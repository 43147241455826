<template>
  <div class="bg_gray">
    <div v-if="isLoading">
      <center id="loadingbox">
        <van-loading size="68px" />
      </center>
    </div>
    <div v-if="!isLoading">
      <headbox
        title="订单报表"
        :isbackPath="true"
        path="person"
        bgColor="#f83637"
        iswhite="true"
      ></headbox>
      <div class="whiteBg">
        <div class="flex top">
          <div class="category"></div>
          <div class="title">买料未结</div>
          <div class="title">卖料未结</div>
          <div class="title">待提料</div>
          <div class="title">待交料</div>
        </div>
        <div class="flex top">
          <div class="category">黄金(克)</div>
          <div class="number">{{ auData.delayBuy }}</div>
          <div class="number">{{ auData.delaySell }}</div>
          <div class="number">{{ auData.buy }}</div>
          <div class="number">{{ auData.sell }}</div>
        </div>
        <div class="flex top">
          <div class="category">白银(克)</div>
          <div class="number">{{ agData.delayBuy }}</div>
          <div class="number">{{ agData.delaySell }}</div>
          <div class="number">{{ agData.buy }}</div>
          <div class="number">{{ agData.sell }}</div>
        </div>
        <div class="flex top">
          <div class="category">铂金(克)</div>
          <div class="number">{{ ptData.delayBuy }}</div>
          <div class="number">{{ ptData.delaySell }}</div>
          <div class="number">{{ ptData.buy }}</div>
          <div class="number">{{ ptData.sell }}</div>
        </div>
        <div class="flex top">
          <div class="category">钯金(克)</div>
          <div class="number">{{ pdData.delayBuy }}</div>
          <div class="number">{{ pdData.delaySell }}</div>
          <div class="number">{{ pdData.buy }}</div>
          <div class="number">{{ pdData.sell }}</div>
        </div>
      </div>
      <div class="whiteBg">
        <div class="flex-between bottom">
          <div>总押金(元)</div>
          <div>{{ orderData.deposit }}</div>
        </div>
        <div class="flex-between bottom">
          <div>可用押金(元)</div>
          <div>{{ orderData.freeEarnestDeposit }}</div>
        </div>
        <div class="flex-between bottom">
          <div>占用押金(元)</div>
          <div>{{ orderData.earnestDeposit }}</div>
        </div>
        <div class="flex-between bottom">
          <div>未结订单保管费(元)</div>
          <div>{{ orderData.unfinishStorage }}</div>
        </div>
        <div class="flex-between bottom">
          <div>卖料未结总押金比例</div>
          <div>{{ orderData.sellDepositPercent }}%</div>
        </div>
        <div class="flex-between bottom">
          <div>买料未结总押金比例</div>
          <div>{{ orderData.buyDepositPercent }}%</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import headbox from "@/components/head.vue";
import { getOrderData } from "@/utils/api";
export default {
  components: {
    headbox
  },
  data() {
    return {
      isLoading: true, //加载状态
      orderData: {}, // 订单报表数据
      auData: { buy: 0, sell: 0, delayBuy: 0, delaySell: 0 },
      agData: { buy: 0, sell: 0, delayBuy: 0, delaySell: 0 },
      ptData: { buy: 0, sell: 0, delayBuy: 0, delaySell: 0 },
      pdData: { buy: 0, sell: 0, delayBuy: 0, delaySell: 0 }
    };
  },
  mounted() {
    getOrderData().then(res => {
      this.orderData = res;
      res.auMap.map(items => {
        if (items.businessType === "3" || items.businessType === "1") {
          if (items.status === "WAIT_FETCH") {
            this.auData.delayBuy += items.weight;
          } else {
            this.auData.delaySell += items.weight;
          }
        } else {
          if (items.status === "WAIT_FETCH") {
            this.auData.buy += items.freeWeight;
          } else {
            this.auData.sell += items.freeWeight;
          }
        }
      });
      res.agMap.map(items => {
        if (items.businessType === "3" || items.businessType === "1") {
          if (items.status === "WAIT_FETCH") {
            this.agData.delayBuy += items.weight;
          } else {
            this.agData.delaySell += items.weight;
          }
        } else {
          if (items.status === "WAIT_FETCH") {
            this.agData.buy += items.freeWeight;
          } else {
            this.agData.sell += items.freeWeight;
          }
        }
      });
      res.ptMap.map(items => {
        if (items.businessType === "3" || items.businessType === "1") {
          if (items.status === "WAIT_FETCH") {
            this.ptData.delayBuy += items.weight;
          } else {
            this.ptData.delaySell += items.weight;
          }
        } else {
          if (items.status === "WAIT_FETCH") {
            this.ptData.buy += items.freeWeight;
          } else {
            this.ptData.sell += items.freeWeight;
          }
        }
      });
      res.pdMap.map(items => {
        if (items.businessType === "3" || items.businessType === "1") {
          if (items.status === "WAIT_FETCH") {
            this.pdData.delayBuy += items.weight;
          } else {
            this.pdData.delaySell += items.weight;
          }
        } else {
          if (items.status === "WAIT_FETCH") {
            this.pdData.buy += items.freeWeight;
          } else {
            this.pdData.sell += items.freeWeight;
          }
        }
      });
      this.isLoading = false;
    });
  },
  methods: {}
};
</script>

<style lang="scss" scoped="true">
.bg_gray {
  background: #f5f5f5;
  width: 100%;
  height: 100%;
  //position: fixed;
  //top: 0;
  //left: 0;
  //z-index: 1;
}
.whiteBg {
  margin: 24px;
  padding: 0 24px;
  background: #ffffff;
  font-size: 24px;
  color: #333333;
  .top {
    padding-top: 32px;
    text-align: center;
    .category {
      width: 98px;
    }
    .title {
      width: 100px;
      color: #919191;
      margin-left: 40px;
    }
    .number {
      width: 100px;
      margin-left: 40px;
    }
    &:last-child {
      padding-bottom: 32px;
    }
  }
  .bottom {
    font-size: 28px;
    padding-top: 24px;
    &:first-child {
      padding-top: 32px;
    }
    &:last-child {
      padding-bottom: 32px;
    }
  }
}
</style>
